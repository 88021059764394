import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { Button } from '@trexity/common-client/lib/components'
import MerchantSignUpButton from '../components/merchantSignUpButton'

export default function Merchants () {
  const [videoVisible, setVideoVisible] = React.useState(false)

  return (
    <Layout type='merchant'>
      <section>
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className='inner'>
              <h1>Take control of your local delivery. <br className='d-only' />Complete the customer experience.</h1>
              <p className='lede mb'>Own your customer’s doorstep.</p>
              <a
                className='button-link button-link-brand'
                onClick={() => { setVideoVisible(!videoVisible) }}
              >
                {!videoVisible ? 'Watch Trexity Overview' : 'Close video'}
              </a>
              {videoVisible ? (
                <div className='responsive-video mt mb'>
                  <iframe
                    src="https://www.youtube.com/embed/e02evQNR-U0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row reverse equal align-center' style={{ minHeight: 400 }}>
          <div className='col'>
            <div className="inner image">
              <img src="/img/merchant-tracking.jpg" alt="Sun and calendar" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Real-time tracking and notifications</h2>
              <p>Live tracking pages combined with email and SMS notifications keeps everyone informed and reduces the chance of packages being stolen or returned.</p>
              {/* <Link to='/merchants' className='cta-link'>See example <i className="material-icons">keyboard_arrow_right</i></Link> */}
            </div>
          </div>
        </div>
      </section>

      <section id='merchant-support'>
        <div className='row w75 align-center justify-center'>
          <div className='col well relative'>
            <div className="inner">
              <h2>Support when you need it</h2>
              <p className='mb'>Our in house merchant support team is human, just like you, and they’re just one click away to answer any questions or solve any issues. Growing a business is hard enough, let us help.</p>
              <ol>
                <li className='mb row preserve align-center'>
                  <div className='tile' style={{ marginRight: 20 }}><i className='material-icons'>forum</i></div>
                  <div>
                    <p className="context">LIVE CHAT</p>
                    Direct access through your Merchant portal.
                  </div>
                </li>
                <li className='mb row preserve align-center'>
                  <div className='tile' style={{ marginRight: 20 }}><i className='material-icons'>description</i></div>
                  <div>
                    <p className="context">KNOWLEDGE</p>
                    Up to date documentation in our knowledge centre.
                  </div>
                </li>
                <li className='row preserve align-center'>
                  <div className='tile' style={{ marginRight: 20 }}><i className='material-icons'>phone</i></div>
                  <div>
                    <p className="context">PHONE</p>
                    When chat is not enough, our team is ready to assist.
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/merchant-labels.jpg" alt="box showing label on it" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Auto-generated labels</h2>
              <p className='mb'>Download, print, and attach to your package, that’s it. Delivery labels are created immediately when you request them—no more hand cramps.</p>
              <MerchantSignUpButton style={{ marginBottom: 15 }} />
              <p className="mice">Each package for delivery requires a valid Trexity label.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h2>Adapt to your customers</h2>
              <p className='mb'>Through our integrations, you can choose which portion of our cost is passed on to the customer.</p>
              <ol className='mb'>
                <li className='mb row preserve align-center'>
                  <div className='tile' style={{ marginRight: 20 }}><i className='material-icons'>timelapse</i></div>
                  <div>
                    <p className="context">FLAT RATE</p>
                    Set a flat rate for your customers, cover the rest.
                  </div>
                </li>
                <li className='mb row preserve align-center'>
                  <div className='tile' style={{ marginRight: 20 }}><i className='material-icons'>circle</i></div>
                  <div>
                    <p className="context">FULL AMOUNT</p>
                    The full cost is passed on to your customer.
                  </div>
                </li>
                <li className='row preserve align-center'>
                  <div className='tile' style={{ marginRight: 20 }}><i className='material-icons'>radio_button_unchecked</i></div>
                  <div>
                    <p className="context">FREE DELIVERY</p>
                    Cover the full cost for your customer.
                  </div>
                </li>
              </ol>
              <Link to='/pricing/' className='cta-link'>See pricing <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/merchant-adapt.jpg" alt="woman with cost options" />
            </div>
          </div>
        </div>
      </section>

      <section className='well callout'>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/merchant-talk.jpg" alt="Man talking with someone over the computer" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Talk with a human</h2>
              <p>Diving into the world of local delivery and managing your own logistics can be intimidating—book a virtual meeting with us and we’ll show you how easy it can be.</p>
              <a href="https://calendly.com/trexitysales/30min" target='_blank' rel='noopener noreferrer' className='cta-link'>Book a meeting <i className="material-icons">keyboard_arrow_right</i></a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal'>
          <div className='col mr m-mr-0'>
            <div className="well inner half row">
              <i className='material-icons mb mr' style={{ fontSize: 40, color: '#4351ee' }}>favorite</i>
              <div>
                <h3>Build customer trust</h3>
                <p>Start changing shopper habits and build customer trust by delivering their order as fast, if not faster, than the big competition.</p>
              </div>
            </div>
          </div>
          <div className='col ml m-ml-0'>
            <div className="well inner half row">
              <i className='material-icons mb mr' style={{ fontSize: 40, color: '#4351ee' }}>verified_user</i>
              <div>
                <h3>Prevent theft</h3>
                <p>Verify that your package gets right into your customer’s hands by selecting our in-person hand off requirement. Our drivers will return the item if they can’t reach someone.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col w75 text-center'>
            <div className="inner">
              <h2>Flexible, scalable, dependable</h2>
              <p className='mb'>With a driver community working around the clock, you can deliver to your customers when you want or when they want. No more delivery windows or black box systems to navigate around—just deliver.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
